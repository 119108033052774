import styled from '@emotion/styled';
import { theme } from '../../styles';

export const HeadingDownloadIconContainer = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.black};
  padding: 0.5rem 0.75rem;
  gap: 0.4em;
  font-family: ${theme.fontFamily.secondary};
  text-decoration: none !important;
  width: fit-content;

  @media (max-width: ${theme.breakpoints.md}) {
    position: relative;
    margin-bottom: 20px;
  }

  span {
    text-align: center;
    line-height: 1.2;
    font-weight: 500;
    font-size: 0.75rem;
    text-transform: uppercase;
    padding-top: 0.1em;
    
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  &:hover {
    border-color: ${theme.colors.primaryred};
    color: ${theme.colors.primaryred};
    transition: all 0.12s ease;
  }

`;
import React, { useContext } from "react"
import { HeadingDownloadIconContainer } from './DownloadPDF.css';
import LocaleContext from '../../context/LocaleProvider';
import { ReadFile } from '../../components/Icons/ReadFile';

const translations = {
  en: {
    specSheet: 'Read the spec sheet'
  },
  fr: {
    specSheet: 'Lire le spec sheet'
  }
};

export const DownloadPDF = (props) => {
  const {link, onClick} = props;
  const lang = useContext(LocaleContext);
  const i18n = lang?.i18n[lang?.locale]?.path || 'en';

  const handleLinkClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  const text = translations[i18n].specSheet;

  return (
    <HeadingDownloadIconContainer
      target='_blank'
      download
      href={link}
      rel='noreferrer noopener'
      onClick={handleLinkClick}
    >
      <ReadFile />
      <span>{text}</span>
    </HeadingDownloadIconContainer>
  )
}